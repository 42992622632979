<template>
    <div class="container">
        <Y-header></Y-header>
        <div class="body-container">
            <div class="blocker"></div>
            <banner :data="bannerData" height="600px" :speed="bannerSpeed"></banner>
            <div style="height: 40px"></div>
            <center-title :text="title" bold font-size="24" position="center"></center-title>
            <center-title :text="subtitle" bold font-size="16" position="center"></center-title>

            <div class="richText" v-html="richText"></div>
            <div class="button-box">
                <a-button :disabled="disabledPev" @click="pev">上一篇</a-button>
                <a-button :disabled="disabledNext" @click="next">下一篇</a-button>
                <a-button @click="back">返回列表</a-button>
            </div>
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import banner from "@/components/banner.vue";
import centerTitle from "@/components/centerTitle.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import handleRequest from "@/utils/handleRequest";
import {bannerData, projectCase, selectNews} from "@/api/apis";
import tools from "@/utils/tools";

export default {
    name: "newsDetail",
    components: {
        centerTitle,
        banner,
        'Y-footer':footer,
        'Y-header':header,
    },
    data() {
        return {
            bannerData:[],
            bannerSpeed:2000,
            title:'',
            subtitle:'',
            richText:'',
            id:'',
            listData:[],
            curIndex:0,
            disabledNext:false,
            disabledPev:false,
        }
    },
    mounted() {
        this.getBannerData();
        this.getListData();
        this.id = this.$route.query.id
        console.log(this.curIndex)

    },
    methods: {
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'最新动态',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner

        },
        async getListData(){
            const data = handleRequest(await selectNews({}))
            let ListData = data.content
            ListData.forEach(item=>{
                item.coverImg = JSON.parse(item.coverImg)
            })
            this.listData = ListData
            this.listData.forEach(item=>{
                if(this.id == item.id){
                    this.title = item.name
                    this.subtitle = item.publisher
                    this.richText = item.content
                }
            })
            this.getCurCaseIndex();

        },
        getCurCaseIndex(){
            this.listData.forEach((item,i)=>{
                if(this.id == item.id){
                    this.curIndex = i
                }
            })
            this.disabledPev = this.curIndex == 0 ? true:false
            this.disabledNext = this.curIndex == this.listData.length-1 ? true:false
        },
        pev(){
            if(this.curIndex == 0 ){
                this.disabledPev = true
                return
            }
            this.disabledNext = false
            this.curIndex--
            this.title = this.listData[this.curIndex].name
            this.subtitle = this.listData[this.curIndex].publisher
            this.richText = this.listData[this.curIndex].content
        },
        next(){
            if(this.curIndex == this.listData.length-1){
                this.disabledNext = true
                return
            }
            this.disabledPev = false
            this.curIndex++
            this.title = this.listData[this.curIndex].name
            this.subtitle = this.listData[this.curIndex].publisher
            this.richText = this.listData[this.curIndex].content
        },
        back(){
            this.$router.go(-1)
        },
    },
}
</script>

<style scoped lang="scss">
.container{
    .body-container{
        .blocker{
            height: 92px;
        }
        .richText{
            margin:0 320px;
            padding:20px 30px;
            :not(not){
                //text-align: center;
            }
        }
        .button-box{
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            button{
                margin: 0 40px;
            }
        }
    }
}
</style>
